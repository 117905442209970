
// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import url("https://use.typekit.net/dat5eut.css");

.logo-1{
    margin-top: 1px;
    height: 100px;
    width: 364px;
}
    .logo-1 {
        height: 70px;
        width: 250px;

        @media #{$laptop-device} {
            height: 70px;
            width: 250px;
        }
        @media #{$md-layout} {
            height: 70px;
            width: 250px;
        }
        @media #{$sm-layout} {
            height: 70px;
            width: 250px;
        }
        @media #{$lg-layout} {
            height: 70px;
            width: 250px;
        }
        @media only screen and (max-width: 767px){

            .logo.img {
                width: 120px;
            }
        }

            
    }

h3.title2::after{
    background-color: #ec008d; 
    bottom: -60px;
    content: '';
    display: block;
    height: 2px;
    right: 71%;
    position: absolute;
    transform: translate(-50%,0);
    width: 150px;
}



.contactForm.container.row{
    align-items: center;
    position: relative;
}

.row{
    display: flex;
    justify-content: center;
    font-family: "proxima-nova";

    font-size: large;
}
.slider-activation{
    padding-top: 112px;
  
}
hr {
    border: 0;
    clear:both;
    display:block;
    width: 96%;               
    background-color:#5a5a5ab7;
    height: 1px;
    opacity: 14%;
  }
  fas{
      justify-items: center;
  }
  


#vulcano div .col-lg-5{
    margin-top: -5rem;
    margin-bottom: -5rem;
   
}



h3.white-text.pt-10 {

    padding-top: 45px;
    padding-bottom: 45px;
    margin-top: 65px;
    
}

h3.white-text.pt-10:after{

    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 20%; /* Change this to whatever width you want. */
    padding-top: 15px; /* This creates some space between the element and the border. */
    padding-bottom: 25px;
    border-bottom: 2px solid #ec008d; /* This creates the border. Replace black with whatever color you want. */


}

#vulcano{
    background-color: black;
}


div.container.my-5.p-5.z-depth-0{

    padding-top: 100px;
}




div.my-0.px-5.pb-5.pt-5.bg_color--2.my-.py-.z-depth-1{

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

h3#firstText.black-text{
    //border-bottom-style: solid;
    border-bottom-width: 1px;
    //border-bottom-color: #ec008d;
    width: auto;


    
}

h3#firstText.black-text:after{
    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 2px; /* This will center the border. */
    width: 10%; /* Change this to whatever width you want. */
    padding-top: 15px; /* This creates some space between the element and the border. */
    border-bottom: 2px solid #ec008d; /* This creates the border. Replace black with whatever color you want. */

   
}

div#firstSubTitel.col-lg-7{
    padding-left: 19%;
}

div.slide.slide-style-2.d-flex.align-items-center.justify-content-center{

    width: 100%;
    height: 0;
    padding: 0 0 43.5%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
}
 h3{
     font-weight: normal;
 }
 h4 {
    font-weight: normal;
 }
 p.niceToKnow{
   color: #9e9e9e;
 }

 @media only screen and (max-width: 600px) {
    div.slide {
        
    }
  }